<template>
  <div class='my-wallets-section' v-if="this.translateData">
    <div class='my-wallets-section__title'>{{ this.translateData.menu_wallets }}</div>
    <div class='my-wallets-section__title-block'>
      {{ this.translateData.menu_wallets }} Perfect Money
    </div>
    <div class='my-wallets-section__wallet-block' v-if='this.wallets'>
      <div
        class='my-wallets-section__wallet'
        v-for='wallet in this.wallets'
        :key='wallet.id'
      >
        <div class='my-wallets-section__wallet-name'>{{ wallet.name }}</div>
        <div class='my-wallets-section__wallet-number'>
          {{ wallet.wallet_id }}
        </div>
        <div
          class='my-wallets-section__wallet-delete'
          @click='deletePopupOpen(wallet.id)'
        >
          {{ this.translateData.delete }}
        </div>
      </div>
    </div>
    <div class='my-wallets-section__button-container'>
      <MainButton class='my-wallets-section__button' @click='goToNewWallet()'>
        {{ this.translateData.add_new }}
      </MainButton>
    </div>
    <RefLink />
    <DefaultPopup
      v-if='isModalOpen'
      :message='this.dataPopup'
      :on-close='handleCloseModal'
    >
      <div class="my-wallets-section__buttons-group">
        <div class="my-wallets-section__button-popup" @click='deleteWallet(this.id)'>{{ this.translateData.yes }}</div>
        <div
          class='my-wallets-section__button-popup my-wallets-section__button-no'
          @click='handleCloseModal'
        >
          {{ this.translateData.no }}
        </div>
      </div>
    </DefaultPopup>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import RefLink from '@/components/molecules/RefLink.vue'
import MainButton from '@/components/atoms/buttons/MainButton.vue'

export default defineComponent({
  name: 'MyWalletsSection',
  data () {
    return {
      wallets: null,
      isModalOpen: false,
      id: null,
      translateData: {},
      dataPopup: ''
    }
  },
  components: {
    DefaultPopup,
    MainButton,
    RefLink
  },
  mounted () {
    this.getAllWallets()
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('menu_wallets', 'delete', 'add_new', 'sure_to_delete_wallet', 'yes', 'no')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
          this.dataPopup = this.translateData.sure_to_delete_wallet
        },
        (error) => {
          console.log(error)
        }
      )
    },
    goToNewWallet () {
      this.$router.push('/dashboard/new-wallet')
    },
    getAllWallets () {
      this.$store.dispatch('getPayments/getWallets').then(
        (response) => {
          this.wallets = response
        },
        (error) => {
          console.log(error)
        }
      )
    },
    deleteWallet (data) {
      this.$store.dispatch('getPayments/deleteWallet', data).then(
        () => {
          this.getAllWallets()
          this.isModalOpen = false
        },
        (error) => {
          console.log(error)
        }
      )
    },
    deletePopupOpen (id) {
      this.isModalOpen = true
      this.id = id
    },
    handleCloseModal () {
      this.isModalOpen = false
    }
  }
})
</script>

<style lang='scss' scoped>
.my-wallets-section {
  padding: 80px 5% 0 45px;
  color: #25262C;

  &__title {
    @include dashbordTitle;
    padding-bottom: 20px;
    text-align: left;
  }

  &__title-block {
    font-size: 16px;
    text-align: left;
  }

  &__wallet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 286px;
    height: 145px;
    border-radius: 10px;
background: #F3F4F5;
    margin: 15px 15px 0 0;
    padding: 0 0 0 30px;
  }

  &__wallet-name {
    font-size: 16px;
    padding-bottom: 5px;
  }

  &__wallet-number {
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 5px;
  }

  &__wallet-delete {
    font-size: 14px;
    color: #272AE5;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__buttons-group {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }

  &__wallet-block {
    display: flex;
  }

  &__button {
    width: 280px;
    height: 48px;
  }

  &__button-popup {
    border-radius: 15px;
    background: #272AE5;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
    width: 160px;
    height: 48px;
    border: none;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    position: relative;
    z-index: 1;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;

    &:hover {
      transition: all .3s ease-out;
      border: 2px solid #272AE5;
      background: #fff;
      color: #25262C;
      cursor: pointer;
    }

    &:active {
      color: #272AE5;
    }
  }

  &__button-container {
    position: relative;
    margin: 30px 0 0 0;
  }
}

@media (min-width: 1650px) {
  .my-wallets-section {
    &__button {
      width: 380px;
    }
    &__wallet {
      width: 400px;
    }
  }
}

@media (max-width: $break-xlg) {
  .my-wallets-section {
    &__wallet {
      width: 22%;
    }
  }
}

@media (max-width: $break-lg) {
  .my-wallets-section {
    &__wallet {
      width: 30%;
    }
  }
}

@media (max-width: $break-md) {
  .my-wallets-section {
    &__wallet {
      width: 46%;
    }
  }
}

@media (max-width: 1200px) {
  .my-wallets-section {
    padding: 50px 20px 0;
  }
}

@media (max-width: $break-sm) {
  .my-wallets-section {
    padding: 80px 10px;
    width: 100%;
    background: #F5F5F5;
    height: 330px;

    &__title {
      font-size: 16px;
      padding-bottom: 15px;
    }

    &__wallet {
      width: 224px;
      height: 100px;
      margin: 0 0 10px 0;
      padding-left: 10px;
    }

    &__wallet-block {
      flex-direction: column;
    }

    &__wallet-name {
      font-size: 14px;
    }
    &__wallet-number {
      font-size: 24px;
    }

    &__button {
      width: 224px;
      height: 40px;
      border-radius: 12px;
      margin: 10px 0 0 0;
    }
    &__button-container {
      margin: 10px 0 0 0;
    }
    &__title-block {
      font-size: 14px;
      padding-bottom: 10px;
    }
  }
}
</style>
